import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Subscription } from "rxjs";
import { TrakkerService } from "src/app/_services/trakker.service";

@Component({
  selector: "app-creategeofence",
  templateUrl: "./creategeofence.component.html",
  styleUrls: ["./creategeofence.component.scss"],
})
export class CreategeofenceComponent {
  @Input() geofence = null;
  @Input() trakkers = [];
  @Output() emitEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveGeofence: EventEmitter<any> = new EventEmitter<any>();
  @Output() addTrackersEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() editScheduleEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() updateChanges: EventEmitter<any> = new EventEmitter<any>();

  private subscriptions: Subscription[] = [];

  done = false;
  alertModes = {
    incoming: false,
    outgoing: false,
  };
  page = 1;
  loadingPage = 1;

  noTriggerWarning = false;
  noGeoDrawedWarning = false;
  bothTriggersSelected = false;

  selectedTrakkers = [];
  visibleAddTrakkers = [];

  constructor(private trakkerService: TrakkerService) {}

  ngOnChanges(changes) {
    if (changes?.trakkers) {
      this.visibleAddTrakkers = this.trakkers;
    }
    // Validate geofence was created successfully before going to next page
    if (this.geofence.GeofenceId != -1 && this.page != this.loadingPage) {
      this.page = this.loadingPage;
    }
  }

  ngOnDestroy() {
    if (!this.done) {
      this.done = true;
      if (this.geofence.GeofenceId >= 0) {
        this.emitEvent.emit("Stop&Del");
      } else {
        this.emitEvent.emit("Stop");
      }
    }
  }

  EditSchedule() {
    this.editScheduleEvent.emit(this.geofence);
  }

  UpdateChanges(geofence) {
    this.updateChanges.emit(geofence);
  }

  AddTrackers() {
    const tmp = { ...this.geofence };
    this.selectedTrakkers.forEach((trakkerId) => {
      const trakker = this.trakkers.find((t) => {
        return t.TrakkerId === trakkerId;
      });
      if (!trakker) {
        return;
      }
      this.subscriptions.push(
        this.trakkerService.addTrakkerGeofence(trakker.TrakkerId, this.geofence.GeofenceId).subscribe((res) => {})
      );
    });

    this.selectedTrakkers.forEach((trakkerId) => {
      const trakker = this.trakkers.find((t) => {
        return t.TrakkerId === trakkerId;
      });
      if (trakker) {
        tmp.Trakkers.push(trakker);
      }
    });

    this.UpdateChanges(tmp);
  }

  AddOrRemoveTracker(trakkerId) {
    const index = this.selectedTrakkers.indexOf(trakkerId);
    if (index === -1) {
      this.selectedTrakkers.push(trakkerId);
    } else {
      this.selectedTrakkers.splice(index, 1);
    }
  }

  UpdateVisibleAddTrakkers(event) {
    if (event.target.value == "") this.visibleAddTrakkers = this.trakkers;
    var searchText = event.target.value.toLowerCase();
    this.visibleAddTrakkers = this.trakkers.filter(function (t) {
      if (t.Name.toLowerCase().indexOf(searchText) !== -1) return true;
      if (
        t.Vehicles != null &&
        t.Vehicles.length > 0 &&
        ((t.Vehicles[0].VehicleRegistrationNumber != null &&
          t.Vehicles[0].VehicleRegistrationNumber.toLowerCase().indexOf(searchText) !== -1) ||
          (t.Vehicles[0].Name != null && t.Vehicles[0].Name.toLowerCase().indexOf(searchText) !== -1) ||
          (t.Vehicles[0].Driver != null && t.Vehicles[0].Driver.toLowerCase().indexOf(searchText) !== -1))
      )
        return true;
      return false;
    });
  }

  geofenceAlart(type: string, state: boolean) {
    if (type === "incoming") {
      this.alertModes.incoming = state;
    } else if (type === "outgoing") {
      this.alertModes.outgoing = state;
    }

    const { incoming, outgoing } = this.alertModes;
    const tmp = { ...this.geofence };
    this.bothTriggersSelected = false;
    if (incoming && outgoing) {
      tmp.GeofenceType = "Intersect";
      this.bothTriggersSelected = true;
    } else if (incoming) {
      tmp.GeofenceType = "Enter";
    } else if (outgoing) {
      tmp.GeofenceType = "Exit";
    } else {
      this.noTriggerWarning = true;
    }

    this.UpdateChanges(tmp);
  }

  ChangeTimeControl(state: boolean) {
    const tmp = { ...this.geofence };
    tmp.IsTimeControlled = state;
    this.UpdateChanges(tmp);
  }

  CheckInputPage1(): boolean {
    let ok = true;
    const { incoming, outgoing } = this.alertModes;
    if (!(incoming || outgoing)) {
      this.noTriggerWarning = true;
      ok = false;
    } else {
      this.noTriggerWarning = false;
    }

    if (!this.geofence.GeoJsonFeature) {
      this.noGeoDrawedWarning = true;
      ok = false;
    } else {
      this.noGeoDrawedWarning = false;
    }

    return ok;
  }

  SaveCreateGeofence(): boolean {
    if (!this.CheckInputPage1()) {
      return false;
    }
    this.saveGeofence.emit();
    return true;
  }

  goToPage(pageNumber: number) {
    switch (this.page) {
      case 1:
        const savedOK = this.SaveCreateGeofence();
        if (!savedOK) {
          return;
        } else {
          this.loadingPage = pageNumber;
          return;
        }
      case 2:
        this.AddTrackers();
        break;
    }

    this.loadingPage = pageNumber;
    this.page = pageNumber;
  }

  StopCreateGeofence() {
    this.done = true;
    if (this.geofence.GeofenceId >= 0) {
      this.emitEvent.emit("Stop&Del");
    } else {
      this.emitEvent.emit("Stop");
    }
  }

  DoneCreateGeofence() {
    this.done = true;
    this.emitEvent.emit("Done");
  }

  StartAddPolygonGeofenceShape() {
    this.emitEvent.emit("Polygon");
  }

  StartAddCircleGeofenceShape() {
    this.emitEvent.emit("Circle");
  }

  PreventShortcuts(mouseevent = null) {
    return;
  }
}
