import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sort2"
})
export class Sort2Pipe  implements PipeTransform {
  transform(array: any, field: string, isDescending: boolean): any[] {
    if (!Array.isArray(array)) {
      return;
    }
    var splitField = field.split(".");
    let first = splitField[0].split("[").length > 1 as boolean;

    array.sort((a: any, b: any) => {
      if(first){
        var aa = splitField.length == 1 ? a.Vehicles[0] : a.Vehicles.length > 0 ? a.Vehicles[0][splitField[1]] : null;
        var bb = splitField.length == 1 ? b.Vehicles[0] : b.Vehicles.length > 0 ? b.Vehicles[0][splitField[1]] : null;
      }
      else
      {
        var aa = a[splitField[0]] != null ? splitField.length == 1 ? a[splitField[0]] : a[splitField[0]][splitField[1]] : null;
        var bb = b[splitField[0]] != null ? splitField.length == 1 ? b[splitField[0]] : b[splitField[0]][splitField[1]] : null;
      }
      if(aa == null) aa = "";
      if(bb == null) bb = "";

      if(typeof(aa) === "string")
      {
        aa = aa.toLowerCase();
        bb = bb.toLowerCase();  
      }
      
      if(isDescending == true) [aa,bb] = [bb,aa];

      if (aa < bb) {
        return -1;
      } else if (aa > bb) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }
}
