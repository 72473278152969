<container-element [ngSwitch]="page">
  <h2>{{ "Page.Labels.CREATE_GEOFENCE" | translate }}</h2>

  <!-- Page 1: Create geofence -->
  <div *ngSwitchCase="1">
    <div class="sub-container">
      <h3>{{ "Page.Labels.NAME" | translate }}</h3>
      <input type="text" class="form-control input-sm" placeholder="{{ 'Page.Labels.NAME' | translate }}"
        id="geofenceNameInput" />
    </div>

    <div class="sub-container" style="min-height: 120px">
      <h3>{{ "Page.Labels.SHAPE" | translate }}</h3>
      <input type="radio" id="shapeChoice1" name="shape" value="round" style="display: none" />
      <label for="shapeChoice1" (click)="StartAddCircleGeofenceShape()" title="{{ 'Page.ToolTips.ROUND' | translate }}">
        <img src="assets/icons/svg/geofence-circle.svg" class="svg-form" />
        <!-- Round -->
      </label>

      <input type="radio" id="shapeChoice2" name="shape" value="polygon" style="display: none" />
      <label for="shapeChoice2" (click)="StartAddPolygonGeofenceShape()"
        title="{{ 'Page.ToolTips.POLYGON' | translate }}">
        <img src="assets/icons/svg/geofence-polygon.svg" class="svg-form" />
        <!-- Polygon -->
      </label>

      <p *ngIf="noGeoDrawedWarning == true" style="color: red; margin-left: 6px">
        {{ "Page.Messages.MUST_DRAW_GEO" | translate }}
      </p>
    </div>

    <div class="sub-container">
      <h3>Trigger</h3>

      <div class="column" style="min-height: 100px">
        <div style="justify-content: center; padding: 0px; margin: 0px">
          <img (click)="geofenceAlart('incoming', false)" *ngIf="alertModes.incoming == true"
            src="assets/icons/svg/toggle-on.svg" class="svg" />
          <img (click)="geofenceAlart('incoming', true)" *ngIf="alertModes.incoming == false"
            src="assets/icons/svg/toggle-off.svg" class="svg" />

          <span> {{ "Page.Messages.ARRIVE_GEO" | translate }}</span>
        </div>

        <div style="justify-content: center; padding: 0px; margin: 0px">
          <img (click)="geofenceAlart('outgoing', false)" *ngIf="alertModes.outgoing == true"
            src="assets/icons/svg/toggle-on.svg" class="svg" />
          <img (click)="geofenceAlart('outgoing', true)" *ngIf="alertModes.outgoing == false"
            src="assets/icons/svg/toggle-off.svg" class="svg" />
          <span> {{ "Page.Messages.EXIT_GEO" | translate }}</span>
        </div>

        <p *ngIf="noTriggerWarning == true" style="color: red; margin-left: 6px">
          {{ "Page.Messages.ATLEAST_ONE_TRIGGER_GEO" | translate }}
        </p>
        <div *ngIf="bothTriggersSelected == true"  class="warning-message"
        [innerHTML]="'Page.Html.INTERSECT_GEOFENCE_BUG_NOTICE' | translate">
        </div>
      </div>
    </div>

    <!-- <div class="sub-container">             
            <h3>{{ 'Page.Labels.UNITS' | translate }}</h3>
            <div class="" (click)="geofence.ActiveForAllUnits = false" *ngIf="geofence.ActiveForAllUnits == true" title="{{ 'Page.Messages.GEOFENCE_ACTIVE_FOR_ALL_UNITS' | translate }}">
                <img src="assets/icons/svg/toggle-on.svg" class="svg">
                <span>{{ 'Page.Messages.GEOFENCE_ACTIVE_FOR_ALL_UNITS' | translate }}</span>
            </div>
            <div class="" (click)="geofence.ActiveForAllUnits = true" *ngIf="geofence.ActiveForAllUnits == false" title="{{ 'Page.Messages.GEOFENCE_ONLY_ACTIVE_FOR_SELECTED_UNITS' | translate }}">
                <img src="assets/icons/svg/toggle-off.svg" class="svg">
                <span>{{ 'Page.Messages.GEOFENCE_ONLY_ACTIVE_FOR_SELECTED_UNITS' | translate }}</span>
            </div>
            <div *ngIf="geofence.ActiveForAllUnits == false">
                {{ 'Page.Messages.IT_IS_POSSIBLE_TO_SELECT_UNITS_AFTER_IT_HAVE_BEEN_SAVED' | translate }}
            </div>
        </div>  -->

    <!-- <div class="sub-container"> 
            <div class="f-row" style="justify-content: flex-start; margin: 16px 0;">
                <button class="btn-base btn-options" (click)="StopCreateGeofence()">{{ 'Page.Buttons.CANCEL' | translate }}</button>
                <button class="btn-base btn-save" (click)="SaveCreateGeofence()">{{ 'Page.Buttons.SAVE' | translate }}</button>
            </div>
        </div>   -->
  </div>

  <div *ngSwitchCase="2">
    <div class="sub-container">
      <h3>{{ "Page.Labels.UNITS" | translate }}</h3>
      <div class="" (click)="geofence.ActiveForAllUnits = false" *ngIf="geofence.ActiveForAllUnits == true"
        title="{{ 'Page.Messages.GEOFENCE_ACTIVE_FOR_ALL_UNITS' | translate }}">
        <img src="assets/icons/svg/toggle-on.svg" class="svg" />
        <span>{{
          "Page.Messages.GEOFENCE_ACTIVE_FOR_ALL_UNITS" | translate
          }}</span>
      </div>
      <div class="" (click)="geofence.ActiveForAllUnits = true" *ngIf="geofence.ActiveForAllUnits == false" title="{{
          'Page.Messages.GEOFENCE_ONLY_ACTIVE_FOR_SELECTED_UNITS' | translate
        }}">
        <img src="assets/icons/svg/toggle-off.svg" class="svg" />
        <span>{{
          "Page.Messages.GEOFENCE_ONLY_ACTIVE_FOR_SELECTED_UNITS" | translate
          }}</span>
      </div>
      <div *ngIf="geofence.ActiveForAllUnits == false">
        <div>
          <!-- <h2>{{ 'Page.ToolTips.CHANGE_CONNECTED_UNITS' | translate }} </h2> -->
          <input class="form-control input-sm defaultAction" id="searchText"
            placeholder="{{ 'Page.Labels.SEARCH_UNIT' | translate }}" type="text"
            (keyup)="UpdateVisibleAddTrakkers($event)" />
        </div>
        <div class="list-container">
          <div class="list">
            <ng-container *ngFor="
                let trakker of visibleAddTrakkers | sort: 'Name';
                trackBy: TrakkerIdentify
              ">
              <ng-template [ngIf]="
                  selectedTrakkers.indexOf(trakker.TrakkerId) !== -1 ||
                  geofence.ActiveForAllUnits
                " [ngIfElse]="notSelected">
                <div (click)="AddOrRemoveTracker(trakker.TrakkerId)">
                  <img src="assets/icons/svg/checkbox-true.svg" class="smal-svg" style="margin-top: -3px" />
                  {{ trakker.Name }}
                </div>
              </ng-template>

              <ng-template #notSelected>
                <div (click)="AddOrRemoveTracker(trakker.TrakkerId)">
                  <img src="assets/icons/svg/checkbox-false.svg" class="smal-svg" style="margin-top: -3px" />
                  {{ trakker.Name }}
                </div>
              </ng-template>
              <!-- <div *ngIf="IsTrakkerConnectedToObject(trakker)==false" class="connectionObject" (click)="AddTrakkerToObject(selectedObject,trakker)"> -->
              <!-- <div *ngIf="true == false" class="connectionObject" (click)="console.log('AddTrakkerToObject(selectedObject,trakker)')">
                        <img src="assets/icons/svg/checkbox-false.svg" class="smal-svg" style="margin-top: -3px;">
                        {{trakker.Name}}
                    </div> -->
              <!-- <div *ngIf="IsTrakkerConnectedToObject(trakker)==true" class="connectionObject connected" (click)="RemoveGeofenceFromObject(trakker,selectedObject)"> -->
              <!-- <div *ngIf="selectedTrakkers.indexOf(trakker.TrakkerId) !== -1" class="connectionObject connected" (click)="console.log('RemoveGeofenceFromObject(trakker,selectedObject)')">
                        <img src="assets/icons/svg/checkbox-true.svg" class="smal-svg" style="margin-top: -3px;">
                        {{trakker.Name}}
                    </div> -->
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="sub-container"> 
            <div class="f-row" style="justify-content: flex-start; margin: 16px 0;">
                <button class="btn-base btn-options" (click)="StopCreateGeofence()">{{ 'Page.Buttons.CANCEL' | translate }}</button>
                <button class="btn-base btn-save" (click)="SaveCreateGeofence()">{{ 'Page.Buttons.SAVE' | translate }}</button>
            </div>
        </div>   -->
  </div>

  <div *ngSwitchCase="3">
    <div class="sub-container" style="min-height: 130px">
      <h3>{{ "Page.Labels.TIME_CONTROL" | translate }}</h3>

      <div class="form-check22" style="padding: 0px; margin: 0px">
        <input ng-control="geofence.IsTimeControlled" [value]="false" [(ngModel)]="geofence.IsTimeControlled"
          (click)="ChangeTimeControl(false)" class="form-check-input2" style="width: fit-content; max-width: 20px"
          type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
        <label class="form-check-label2" for="flexRadioDefault1">
          {{ "Page.Messages.ALWAYS_ACTIVE" | translate }}
        </label>
      </div>
      <div class="form-check22">
        <input ng-control="geofence.IsTimeControlled" [value]="true" [(ngModel)]="geofence.IsTimeControlled"
          (click)="ChangeTimeControl(true)" class="form-check-input2" style="width: fit-content; max-width: 20px"
          type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
        <label class="form-check-label2" for="flexRadioDefault2">
          {{ "Page.Messages.SCHEDULE_CONTROLLED" | translate }}
        </label>
      </div>

      <div *ngIf="geofence.IsTimeControlled">
        <button (click)="EditSchedule()" style="background-color: rgb(93, 154, 224)" class="btn-base btn-save">
          {{ "Page.Messages.ADD_SCHEDULE" | translate }}
        </button>
      </div>
    </div>
    <div style="min-height: 50px;"></div>
  </div>



  <div class="sub-container">
    <div class="f-row" style="justify-content: flex-start; margin: 16px 0">
      <button class="btn-base btn-options" (click)="StopCreateGeofence()">
        {{ "Page.Buttons.CANCEL" | translate }}
      </button>

      <button *ngIf="page < 3" class="btn-base btn-save" (click)="goToPage(this.page + 1)">
        {{ "Page.Labels.NEXT" | translate }}
      </button>
      <button (click)="DoneCreateGeofence()" *ngIf="page >= 3" class="btn-base btn-save">{{ "Page.Buttons.SAVE" | translate }}</button>
    </div>
  </div>
</container-element>